<template>
	<div style="padding: 20px;" v-loading="loading">
		<el-row :gutter="20">
			<el-col style="min-height: 600px;" :span="15">
				<el-card :style="{minHeight: '85%',textAlign:yuyan.now=='hanyu'?'left':'right'}" shadow="hover">

					<div class="qu-content">
						<!-- 单选 -->
						<!-- <p>【{{ quData.quType | quTypeFilter }}】{{ quData.content }}</p> -->
						<p :style="{direction: yuyan.now=='hanyu'?'ltr':'rtl',width: '100%',fontSize: '20px'}">
							{{ quData.content }}</p>
						<div v-if="quData.audio">
							<el-tag style="margin:10px 10px 10px 20px;" type="success" v-if="!isPlay" @click="boFang()">
								🔈
							</el-tag>
							<el-tag style="margin:10px 10px 10px 20px;" type="warning" v-if="isPlay" @click="boFang()">
								🔊
							</el-tag>
							<audio ref="audios" autoplay :src="'https://admin.rolumapp.com/upload/'+quData.audio">
							</audio>
						</div>
						<div style="margin: 0 20px;font-size: 30px;font-weight: 900;"
							v-if="quData.quType === 1 || quData.quType === 3 ">
							<el-radio-group style="width: 100;" readonly>
								<!-- v-model="answerValues[0]" -->
								<el-radio v-if="quData.quType === 1" v-for="an in quData.answerList" :label="an.id"
									readonly
									:style="{textAlign: yuyan.now=='hanyu'?'left':'right',paddingLeft: '10px'}">
									<div style="font-size: 18px;">
										<span style="margin-right: 30;">{{ an.abc }}</span> . {{ an.content }}
									</div>
									<div v-if="an.image!==''" style="display: flex;flex-wrap: wrap;">
										<el-image :preview-src-list="[imgShow(an.image)]"
											style="height: 150px;width: auto;" :src="imgShow(an.image)" fit="contain">
										</el-image>
									</div>
								</el-radio>
							</el-radio-group>
						</div>

						<!-- 多选题 -->
						<div v-if="quData.quType === 2" style="margin: 0 20px;font-size: 30px;font-weight: 900;">
							<el-radio-group style="width: 100;" readonly>
								<!-- v-model="answerValues" -->
								<el-radio v-for="an in quData.answerList" :label="an.id"
									:style="{textAlign: yuyan.now=='hanyu'?'left':'right',paddingLeft: '10px'}">
									<div style="font-size: 18px;">
										<span style="margin-right: 30;">{{ an.abc }}</span>.{{ an.content }}
									</div>
									<div v-if="an.image!==''" style="display: flex;flex-wrap: wrap;">
										<el-image :preview-src-list="[imgShow(an.image)]"
											style="height: 150px;width: auto;" :src="imgShow(an.image)" fit="contain">
										</el-image>
									</div>
								</el-radio>
							</el-radio-group>
						</div>

						<!-- <div v-if="analysisShow" style="margin-top: 20px; color: #1890ff; font-weight: bold">
							{{yuyan.jiaojuan_yes}}：{{ rightTags.join(' ') }}
						</div> -->

						<el-row>
							<el-col>
								<div v-if="images.length!==0" style="margin-top: 30px;text-align: center;">
									<el-image v-for="item in images" style="min-height: 200px;width: auto;"
										:preview-src-list="images" :src="item" fit="fill">
										<template #error>
											<div class="image-slot">
											</div>
										</template>
									</el-image>
								</div>
							</el-col>
						</el-row>

					</div>

				</el-card>
				<el-card style="min-height: 15%;" shadow="hover">
					<div style="display: flex;justify-content: space-between;">
						<!-- 时间 -->
						<div><span style="color: #ff0000;font-size: 18px;">{{ min }}:{{ sec }}</span></div>
						<div style="display: flex;">
							<div style="margin: 0 20px;font-size: 30px;font-weight: 900;"
								v-if="quData.quType === 1 || quData.quType===3 ">
								<el-radio-group v-model="radioValue" readonly style="display: inline-flex;"
									v-if="quData.quType === 1" @change="change">
									<el-radio-button v-for="(an,index) in quData.answerList" :label="index" readonly>
										<span
											style="line-height: 30px;font-size: 26px;padding-right: 9px;">{{ an.abc }}</span>
									</el-radio-button>
								</el-radio-group>
								<el-radio-group v-model="radioValue" readonly style="display: inline-flex;"
									v-if="quData.quType === 3" @change="change">
									<el-radio-button v-for="(an,index) in quData.answerList" :label="index" readonly>
										<span style="line-height: 30px;font-size: 26px;padding-right: 9px;"
											v-if="index === 0">✓</span>
										<span style="line-height: 30px;font-size: 26px;padding-right: 9px;"
											v-if="index === 1">✘</span>
									</el-radio-button>
								</el-radio-group>
							</div>
							<div style="margin: 0 20px;font-size: 30px;font-weight: 900;" v-else>
								<el-checkbox-group v-model="multiValue" readonly style="display: inline-flex;"
									@change="change">
									<el-checkbox-button v-for="(an,index) in quData.answerList" :label="index" readonly>
										<span
											style="line-height: 30px;font-size: 26px;padding-right: 9px;">{{ an.abc }}</span>
									</el-checkbox-button>
								</el-checkbox-group>
							</div>

						</div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="9">
				<el-card style="min-height: 100%;" shadow="hover">

					<p class="card-title">{{yuyan.start_answer_card}}</p>
					<el-row :gutter="24" class="card-line" style="padding-left: 10px">
						<el-tag type="info">{{yuyan.start_answerno_card}}</el-tag>
						<el-tag type="success">{{yuyan.start_answerok_card}}</el-tag>
					</el-row>

					<div v-if="paperData.radioList!==undefined && paperData.radioList.length > 0">
						<!-- <p class="card-title">{{yuyan.start_answer_dan}}</p> -->
						<el-row :gutter="24" class="card-line" style="text-align: center;">
							<div v-for="item in paperData.judgeList">
								<span style="color: #909399;font-size: 12px;display: block;">{{ item.sort+1 }}</span>
								<el-tag style="width: 40px;text-align: center;"
									:type="cardItemClass(item.answered, item.quId)" @click="handSave(item)">
									{{ answered[item.id]?answered[item.id]:'-' }}</el-tag>
							</div>
							<div v-for="item in paperData.radioList">
								<span style="color: #909399;font-size: 12px;display: block;">{{ item.sort+1 }}</span>
								<el-tag style="width: 40px;text-align: center;"
									:type="cardItemClass(item.answered, item.quId)" @click="handSave(item)">
									{{ answered[item.id]?answered[item.id]:'-' }}</el-tag>
							</div>
							<div v-for="item in paperData.multiList">
								<span style="color: #909399;font-size: 12px;display: block;">{{ item.sort+1 }}</span>
								<el-tag style="text-align: center;" :type="cardItemClass(item.answered, item.quId)"
									@click="handSave(item)"> {{ answered[item.id]?answered[item.id]:'-' }}</el-tag>
							</div>
						</el-row>
					</div>
					<el-row style="margin-top: 10px;">
						<el-col>
							<el-card shadow="hover">
								<div style="display: flex;justify-content: space-between;">
									<div>{{yuyan.timuleixing}} ({{quData.quType==1?'单选':(quData.quType==2?'多选':'判断')}})
									</div>
									<div>
										<el-button v-if="showPrevious" type="primary" icon="el-icon-back"
											@click="handPrevious()">
											{{yuyan.start_answer_up}}
										</el-button>
										<el-button v-if="showNext" type="primary" icon="el-icon-right"
											@click="handNext()">
											{{yuyan.start_answer_down}}
										</el-button>
										<el-button type="primary" icon="el-icon-plus" :loading="loading"
											@click="handExamButton()">
											{{ handleText }}
										</el-button>
									</div>
								</div>
							</el-card>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
		</el-row>
	</div>
	<el-dialog center title="考试确认窗口" v-model="showHandle">
		<div>
			<div style="font-size: 18px;font-weight: 900;">操作提示</div>
			<div style="width: 58%;height: 200px;margin: 0 auto;">
				<div style="margin: 20px 20px;line-height: 100px;font-size: 16px;">
					1. 点击[确认交卷]，将提交考试成绩，考试结束！
				</div>
				<div style="margin: 20px 20px;line-height: 100px;font-size: 16px;">
					2. 点击[继续考试]，将关闭本窗口，继续考试！
				</div>
			</div>
			<div style="height: 100px;justify-content: space-around;display: flex;">
				<el-button :loading="buttonLoad" @click="handHandExam"
					style="height: 65px;margin-top: 30px;width: 150px;font-size: 18px;font-weight: 400;" type="primary">
					确认交卷</el-button>
				<el-button style="height: 65px;margin-top: 30px;width: 150px;font-size: 18px;font-weight: 400;"
					@click="showHandle = false">继续考试</el-button>
			</div>
		</div>
	</el-dialog>

</template>

<script>
	import {
		paperDetail,
		quDetail,
		handExam,
		fillAnswer
	} from '@/api/starts.js'
	// import { Loading } from 'element-ui'

	export default {
		name: 'ExamProcess',
		data() {
			return {
				buttonLoad: false,
				showHandle: false,
				images: [],
				isPlay: true,
				titleImg: [],
				yuyan: this.$language(),
				// 全屏/不全屏
				isFullscreen: false,
				showPrevious: false,
				showNext: true,
				loading: false,
				handleText: this.$language().start_answer_post,
				pageLoading: false,
				// 试卷ID
				paperId: '',
				// 当前答题卡
				cardItem: {},
				allItem: [],
				// 当前题目内容
				quData: {
					answerList: []
				},
				// 试卷信息
				paperData: {
					leftSeconds: 99999,
					judgeList: [],
					radioList: [],
					multiList: []
				},
				// 单选选定值
				radioValue: '',
				// 多选选定值
				multiValue: [],
				// 已答ID
				answeredIds: [],
				min: '00',
				sec: '00',
				loading: false,
				answered: {},
				abc: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'],
				yn: ['✓', '✘']
			}
		},
		created() {
			this.yuyan = this.$language()
			const id = this.$route.query.id
			if (typeof id !== 'undefined') {
				this.paperId = id
				this.fetchData(id)
			}
			// 缓存里获取用户提交的答案

			if (localStorage.getItem(this.paperId)) {
				if (localStorage.getItem(this.paperId) != "[object Object]") {
					this.answered = JSON.parse(localStorage.getItem(this.paperId))
				}
			}
		},
		watch: {
			'$store.state.language_num'(to, from) {
				this.yuyan = this.$language()
			}
		},
		methods: {
			imgShow(url) {
				var r = new RegExp(/http/g); //构造正则表达式对象
				if (r.test(url))
					return url
				else
					return 'https://admin.rolumapp.com/upload/' + url

			},
			// 存储用户提交的答案
			change(index) {
				// 单选
				if (this.quData.quType == 1) {
					this.answered[this.quData.id] = this.abc[index]
				}
				// 多选
				if (this.quData.quType == 2) {
					let str = "",
						that = this
					index.forEach(function(item) {
						str = str + that.abc[item]
					});
					this.answered[this.quData.id] = str
				}
				// 判断
				if (this.quData.quType == 3) {
					this.answered[this.quData.id] = this.yn[index]
				}
				
				// 保存到缓存里，避免刷新丢失答案
				localStorage.setItem(this.paperId, JSON.stringify(this.answered))
				// 不是多选题自动跳下一题
				if(this.quData.quType != 2){
					this.handNext()
				}
			},
			// 控制播放
			boFang() {
				let player = this.$refs.audios
				this.isPlay = !this.isPlay
				if (this.isPlay) {
					player.play()
				} else {
					player.pause()
				}
			},
			// 倒计时
			countdown() {
				const leftSeconds = this.paperData.leftSeconds

				// 强制交卷
				if (leftSeconds < 0) {
					this.doHandler()
					return
				}

				// 时
				const min = parseInt(leftSeconds / 60 % 60)
				const sec = parseInt(leftSeconds % 60)

				this.min = min > 9 ? min : '0' + min
				this.sec = sec > 9 ? sec : '0' + sec
				const that = this
				this.paperData.leftSeconds -= 1
				setTimeout(function() {
					that.countdown()
				}, 1000)
			},

			// 答题卡样式
			cardItemClass(answered, quId) {
				if (quId === this.cardItem.quId) {
					return 'el-tag el-tag--primary el-tag--dark'
				}

				if (answered) {
					return 'success'
				}

				if (!answered) {
					return 'info'
				}
			},

			/**
			 * 统计有多少题没答的
			 * @returns {number}
			 */
			countNotAnswered() {
				let notAnswered = 0

				this.paperData.judgeList.forEach(function(item) {
					if (!item.answered) {
						notAnswered += 1
					}
				})

				this.paperData.radioList.forEach(function(item) {
					if (!item.answered) {
						notAnswered += 1
					}
				})

				this.paperData.multiList.forEach(function(item) {
					if (!item.answered) {
						notAnswered += 1
					}
				})

				return notAnswered
			},

			/**
			 * 下一题
			 */
			handNext() {
				this.isPlay = true
				this.getImageList()
				const index = this.cardItem.sort + 1
				this.handSave(this.allItem[index])
			},

			/**
			 * 上一题
			 */
			handPrevious() {
				this.getImageList()
				// 将喇叭变为响的
				this.isPlay = true
				const index = this.cardItem.sort - 1
				this.handSave(this.allItem[index])
			},

			doHandler() {
				this.handleText = this.yuyan.start_jiaojuan_wite
				// this.loading = true
				const that = this
				const params = {
					id: this.paperId
				}
				handExam(params).then(() => {
					this.$message({
						message: that.yuyan.start_jiaojuan_tiaozhuan,
						type: 'success'
					})
					this.buttonLoad = false
					this.$router.push({
						path: 'jiaojuan',
						query: {
							id: this.paperId
						}
					})
				})
			},

			handExamButton() {
				this.showHandle = true
			},
			// 交卷操作
			handHandExam() {
				this.loading = true
				this.buttonLoad = true
				// this.showHandle = false
				const that = this
				// 交卷保存答案
				this.handSave(this.cardItem, function() {
					const notAnswered = that.countNotAnswered()

					let msg = that.yuyan.start_jiaojuan

					if (notAnswered > 0) {
						// msg = '您还有' + notAnswered + '题未作答，确认要交卷吗?'
						msg = that.yuyan.start_jiaojuan_tishi + notAnswered
					}

					that.doHandler()
					return
					that.$confirm(msg, '提示', {
						confirmButtonText: that.yuyan.queren,
						cancelButtonText: that.yuyan.quxiao,
						type: 'warning'
					}).then(() => {
						that.doHandler()
					}).catch(() => {
						that.$message({
							type: 'info',
							message: that.yuyan.start_jiaojuan_quxiao
						})
					})
				})
			},

			// 保存答案
			handSave(item, callback) {
				if (item.id === this.allItem[0].id) {
					this.showPrevious = false
				} else {
					this.showPrevious = true
				}

				// 最后一个索引
				const last = this.allItem.length - 1

				if (item.id === this.allItem[last].id) {
					this.showNext = false
				} else {
					this.showNext = true
				}

				const answers = []
				console.log(this.multiValue)
				this.multiValue.forEach((item, index) => {
					answers.push(this.quData.answerList[item].id)
				})
				// for (let i = 0; i < this.multiValue.length; i++) {
				// 	answers.push(this.quData.answerList[i].id)
				// }
				if (this.radioValue !== '') {
					console.log(this.radioValue)
					console.log(this.quData)
					answers.push(this.quData.answerList[this.radioValue].id)
				}

				const params = {
					paperId: this.paperId,
					quId: this.cardItem.quId,
					answers: answers,
					answer: ''
				}
				console.log(params)
				fillAnswer(params).then(() => {
					// 必须选择一个值
					if (answers.length > 0) {
						// 加入已答列表
						this.cardItem.answered = true
					}

					// 最后一个动作，交卷
					if (callback) {
						callback()
					}

					// 查找详情
					this.fetchQuData(item)
				})
			},

			// 试卷详情
			fetchQuData(item) {
				// 打开
				// const loading = Loading.service({
				//   text: '拼命加载中',
				//   background: 'rgba(0, 0, 0, 0.7)'
				// })

				// 获得详情
				this.cardItem = item

				// 查找下个详情
				const params = {
					paperId: this.paperId,
					quId: item.quId
				}
				this.loading = true
				this.images = []
				quDetail(params).then(response => {

					this.quData = response

					if (response.images) {
						this.images = response.images.split("%")
						this.images.forEach((item, index) => {
							var r = new RegExp(/http/g); //构造正则表达式对象
							if (r.test(item)) {} else {
								this.images[index] = 'https://admin.rolumapp.com/upload/' + item
							}
						})
					}
					this.getImageList()
					this.radioValue = ''
					this.multiValue = []

					// 填充该题目的答案
					this.quData.answerList.forEach((item,index) => {
						if ((this.quData.quType === 1 || this.quData.quType === 3) && item.checked) {
							this.radioValue = index
						}

						if (this.quData.quType === 2 && item.checked) {
							this.multiValue.push(item.id)
						}
					})

					// 关闭详情
					this.loading = false

				})
			},
			// 刷新图片  标题
			getImageList() {
				this.titleImg = []
				if (this.quData.images) {
					this.titleImg = this.quData.images
					this.titleImg = this.titleImg.split('%')
					if (this.titleImg[this.titleImg.length - 1] == "")
						this.titleImg.pop()
				}
			},
			// 试卷详情
			fetchData(id) {
				const params = {
					id: id
				}
				paperDetail(params).then(response => {
					// 试卷内容
					this.paperData = response

					// 获得第一题内容
					if (this.paperData.judgeList) {
						this.cardItem = this.paperData.judgeList[0]
					} else if (this.paperData.radioList) {
						this.cardItem = this.paperData.radioList[0]
					} else if (this.paperData.multiList) {
						this.cardItem = this.paperData.multiList[0]
					}

					const that = this

					this.paperData.judgeList.forEach(function(item) {
						that.allItem.push(item)
					})

					this.paperData.radioList.forEach(function(item) {
						that.allItem.push(item)
					})

					this.paperData.multiList.forEach(function(item) {
						that.allItem.push(item)
					})

					// 当前选定
					this.fetchQuData(this.cardItem)

					// 倒计时
					this.countdown()
				})
			}

		}
	}
</script>

<style scoped>
	.zidingyi {
		display: flex;
	}

	.left {
		flex: 5;
	}

	.right {
		margin-left: 10px;
		flex: 2;
	}

	.qu-content div {
		line-height: 30px;
	}

	.qu-analysis p {
		color: #555;
		font-size: 14px
	}

	.qu-analysis-line {
		margin-top: 20px;
		border-bottom: #eee 1px solid
	}

	.el-checkbox-group label,
	.el-radio-group label {
		width: 100%;
		direction: rtl;
	}
</style>

<style scoped lang="scss">
	.el-image /deep/ img {
		width: auto;
		margin: 20px;
	}

	.qu-content div {
		line-height: 30px;
	}

	.el-checkbox-group label,
	.el-radio-group label {
		width: 100%;
	}

	.card-title {
		background: #eee;
		line-height: 35px;
		text-align: center;
		font-size: 14px;
	}

	.card-line {
		padding-left: 10px
	}

	.card-line span {
		cursor: pointer;
		margin: 2px;
	}

	/*去掉radio的小圆点*/
	/deep/ .el-radio__input {
		display: none !important;
	}

	/*去掉radio的小圆点*/
	/deep/ .el-checkbox__input {
		display: none !important;
	}

	/deep/ .el-radio,
	.el-checkbox {
		padding: 9px 20px 9px 10px;
		border-radius: 4px;
		border: 1px solid #dcdfe6;
		margin-bottom: 10px;
	}

	.is-checked {
		border: #409eff 1px solid;
	}

	.el-radio img,
	.el-checkbox img {
		max-width: 200px;
		max-height: 200px;
		border: #dcdfe6 1px dotted;
	}

	.el-radio {
		text-align: right;
		font-size: 24px;
	}
</style>
