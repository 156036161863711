import {request} from '@/sujs/http.js'

/**
 * 创建试卷
 * @param data
 */
export function createPaper(data) {
	return request({
		url:'/paper/paper/create-paper',
		method:"POST",
		data:data
	})
}

/**
 * 试卷详情
 * @param data
 */
export function paperDetail(data) {
	return request({
		url:'/paper/paper/paper-detail',
		method:"POST",
		data:data
	})
}

/**
 * 题目详情
 * @param data
 */
export function quDetail(data) {
	return request({
		url:'/paper/paper/qu-detail',
		method:"POST",
		data:data
	})
}

/**
 * 填充答案
 * @param data
 */
export function fillAnswer(data) {
	return request({
		url:'/paper/paper/fill-answer',
		method:"POST",
		data:data
	})
}

/**
 * 交卷
 * @param data
 */
export function handExam(data) {
	return request({
		url:'/paper/paper/hand-exam',
		method:"POST",
		data:data
	})
}

/**
 * 试卷详情
 * @param data
 */
export function paperResult(data) {
	return request({
		url:'/paper/paper/paper-result',
		method:"POST",
		data:data
	})
}

/**
 * 错题训练
 * @param data
 */
export function training(data) {
	return request({
		url:'/paper/paper/training',
		method:"POST",
		data:data
	})
}
